
import { Vue, Component } from 'vue-property-decorator';
import Swiper from 'swiper';
import contact from '@/components/contact.vue';
import api from '@/api/';
@Component({
 components: {
  contact,
 },
})
export default class Dash extends Vue {
 private article: any = {};
 private mounted() {
  /* const mySwiper = new Swiper('.swiper-container', {
    autoplay: false,
    direction: 'vertical',
    mousewheel: true,
    pagination: {
      el: '.swiper-pagination'
    }
  }) */
  //  api.articleList({categoryId: 16107, saasInfoId: 1}).then((res) => this.article = res.result)
  //  api.articleGet(1001266).then((res) => this.article = res.result)
 }
}
